import React from "react"
import Fade from "react-reveal/Fade"
import Loadable from "@loadable/component"

import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Works from "../../components/Works"
import About from "../../components/About"
import Press from "../../components/Press"
import CinericLogo from "../../components/CinericLogo"

const LoadableEmbedFacebook = Loadable(() =>
  import("../../components/EmbedFacebook")
)

const IndexPage = ({ location }) => {
  const lang = "ja"
  const pageSlug = "index-ja"
  const isLangEn = lang === "en"

  return (
    <Layout lang={lang} pageSlug={pageSlug} location={location}>
      <Hero lang={lang} isLangEn={isLangEn} />
      <section className="intro intersection">
        <div className="intro__items container-narrow">
          <div className="intro__item intro__item--primary font-headline only-for-medium">
            <Fade bottom delay={300} duration={600} distance={"10px"}>
              <div>Ema</div>
            </Fade>
            <Fade bottom delay={400} duration={600} distance={"10px"}>
              <div>Ryan</div>
            </Fade>
            <Fade bottom delay={500} duration={600} distance={"10px"}>
              <div>Yamazaki</div>
            </Fade>
          </div>
          <div className="intro__item intro__item--secondary">
            <Fade bottom delay={700} duration={600} distance={"20px"}>
              <div>
                <span className="em">山崎エマ</span>
                <p>
                  東京を拠点とするドキュメンタリー監督。日本とイギリスの血を引き、ニューヨークにもルーツを持つ。日本人の心を持ちながら外国人の視点が理解できる立場を活かし、人間の葛藤や成功の姿を親密な距離で捉えるドキュメンタリー制作を目指す。
                </p>

                <p>
                  ３本目の長編監督作品『小学校〜それは小さな社会〜』が2023年東京国際映画祭でワールドプレミアし、現在様々な国での映画祭で上映され、配給中。そして、編集と共同プロデュースを務めた伊藤詩織監督の『BLACK
                  BOX
                  DIARIES』が2024年のサンダンス映画祭で上映され、高い評価を得た。
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section id="works" className="works intersection">
        <div className="head__wrapper">
          <Fade bottom delay={300} duration={600} distance={"10px"}>
            <h2 className="head font-headline">Works</h2>
          </Fade>
        </div>
        <Works isLangEn={isLangEn} />
      </section>

      <section id="about" className="about intersection">
        <div className="head__wrapper">
          <Fade bottom delay={300} duration={600} distance={"10px"}>
            <h2 className="head font-headline">About</h2>
          </Fade>
        </div>
        <About isLangEn={isLangEn} />
      </section>

      <section id="contact" className="contact intersection">
        <div className="head__wrapper">
          <Fade bottom delay={300} duration={600} distance={"10px"}>
            <h2 className="head font-headline">Contact</h2>
          </Fade>
        </div>
        <div className="contact__items container-narrow">
          <Fade bottom delay={300} duration={600} distance={"20px"}>
            <div className="contact__item contact__inquiry">
              <CinericLogo />
              <h3 className="contact__item__head">Cineric Creative</h3>
              <div className="contact__item__content">
                <p>
                  シネリック・クリエイティブは、ニューヨークと東京に拠点をもつ映像制作会社。主に日本と海外の国際共同制作を手がけ、野心的なインディペンダント映画の企画・制作を行う。
                  <br />
                  更に、日本や日本語の要素があるプロジェクトの制作サポート、日本のコンテンツの国際化など、コンサルティング・編集・翻訳・英語字幕・トレーラー制作なども行なっています。
                </p>

                <a
                  href="https://cinericcreative.com/en/"
                  className="item__link link-ul"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cinericcreative.com
                </a>
                <div className="item__email">
                  {isLangEn
                    ? "For inquiries, please contact:"
                    : "問い合わせはこちらまで"}
                  <br />
                  <span className="em">team@cinericcreative.com</span>
                </div>
              </div>
            </div>
          </Fade>
          <div className="contact__item contact__facebook">
            <Fade bottom delay={900} duration={600} distance={"20px"}>
              <LoadableEmbedFacebook />
            </Fade>
          </div>
        </div>
      </section>

      <section id="press" className="press intersection">
        <div className="head__wrapper">
          <Fade bottom delay={200} duration={600} distance={"10px"}>
            <h2 className="head font-headline">Press</h2>
          </Fade>
        </div>
        <Press isLangEn={isLangEn} />
      </section>

      <section className="parallax parallax--monkey"></section>
    </Layout>
  )
}

export default IndexPage
