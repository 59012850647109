import React from "react"
import Fade from "react-reveal/Fade"
import Loadable from "@loadable/component"

import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Works from "../../components/Works"
import About from "../../components/About"
import Press from "../../components/Press"
import CinericLogo from "../../components/CinericLogo"

const LoadableEmbedFacebook = Loadable(() =>
  import("../../components/EmbedFacebook")
)

const IndexPage = ({ location }) => {
  const lang = "en"
  const pageSlug = "index-en"
  const isLangEn = lang === "en"

  return (
    <Layout lang={lang} pageSlug={pageSlug} location={location}>
      <Hero lang={lang} isLangEn={isLangEn} />
      <section className="intro intersection">
        <div className="intro__items container-narrow">
          <div className="intro__item intro__item--primary font-headline only-for-medium">
            <Fade bottom delay={300} duration={600} distance={"10px"}>
              <div>Ema</div>
            </Fade>
            <Fade bottom delay={450} duration={600} distance={"10px"}>
              <div>Ryan</div>
            </Fade>
            <Fade bottom delay={550} duration={600} distance={"10px"}>
              <div>Yamazaki</div>
            </Fade>
          </div>
          <div className="intro__item intro__item--secondary">
            <Fade bottom delay={700} duration={600} distance={"20px"}>
              <div>
                <p>
                  <span className="em">Ema Ryan Yamazaki</span> is a
                  Japanese/British documentary filmmaker based in Tokyo, with
                  roots in New York. With a unique perspective as an insider and
                  outsider in Japan, Ema strives to tell stories that
                  empathetically show human struggle and triumph.
                </p>
                <p>
                  Her third feature documentary, THE MAKING OF A JAPANESE,
                  premiered at the Tokyo International Film Festival in 2023 and
                  is currently playing festivals and in distribution around the
                  world. She has also recently served as Editor and Co-Producer
                  for Shiori Ito's BLACK BOX DIARIES, which premiered to
                  critical acclaim at Sundance 2024.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section id="works" className="works intersection">
        <div className="head__wrapper">
          <Fade bottom delay={300} duration={600} distance={"10px"}>
            <h2 className="head font-headline">Works</h2>
          </Fade>
        </div>
        <Works isLangEn={isLangEn} />
      </section>

      <section id="about" className="about intersection">
        <div className="head__wrapper">
          <Fade bottom delay={300} duration={600} distance={"10px"}>
            <h2 className="head font-headline">About</h2>
          </Fade>
        </div>
        <About isLangEn={isLangEn} />
      </section>

      <section id="contact" className="contact intersection">
        <div className="head__wrapper">
          <Fade bottom delay={300} duration={600} distance={"10px"}>
            <h2 className="head font-headline">Contact</h2>
          </Fade>
        </div>
        <div className="contact__items container-narrow">
          <Fade bottom delay={300} duration={600} distance={"20px"}>
            <div className="contact__item contact__inquiry">
              <CinericLogo />
              <h3 className="contact__item__head">Cineric Creative</h3>
              <div className="contact__item__content">
                <p>
                  Cineric Creative is an independent production company based in
                  New York and Tokyo. It is especially focused on international
                  co-productions with Japan, where it has a strong reputation
                  for helping to realize ambitious art-house films. The company
                  provides local production services projects with Japanese
                  elements, including field producing, editing, trailer-making,
                  translation, and subtitles.
                </p>
                <a
                  href="https://cinericcreative.com/en/"
                  className="item__link link-ul"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cinericcreative.com
                </a>
                <div className="item__email">
                  For inquiries, please contact:
                  <br />
                  <span className="em">team@cinericcreative.com</span>
                </div>
              </div>
            </div>
          </Fade>
          <div className="contact__item contact__facebook">
            <Fade bottom delay={900} duration={600} distance={"20px"}>
              <LoadableEmbedFacebook />
            </Fade>
          </div>
        </div>
      </section>

      <section id="press" className="press intersection">
        <div className="head__wrapper">
          <Fade bottom delay={200} duration={600} distance={"10px"}>
            <h2 className="head font-headline">Press</h2>
          </Fade>
        </div>
        <Press isLangEn={isLangEn} />
      </section>

      <section className="parallax parallax--monkey"></section>
    </Layout>
  )
}

export default IndexPage
